<template>
  <vx-table :resource="resource" :columns="columns" :filters="filters" />
</template>

<script>
import { VxCellDate, VxCellLink, VxTable } from '@/components/table'
import { provablyFair } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { buildRoute, path } from '@/router'

export default {
  name: 'Rolls',
  components: {
    VxTable,
    VxCellDate,
    VxCellLink
  },
  setup () {
    const resource = provablyFair.rolls

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'server_seed_id',
        component: VxCellLink,
        tdAttr: (id) => ({
          label: `#${id}`,
          href: buildRoute(path.serverSeeds, { query: { id } })
        })
      },
      {
        key: 'client_seed_id',
        component: VxCellLink,
        tdAttr: (id) => ({
          label: `#${id}`,
          href: buildRoute(path.clientSeeds, { query: { id } })
        })
      },
      {
        key: 'nonce',
        sortable: true
      },
      {
        key: 'random_value',
        label: 'Roll'
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'server_seed_id',
        type: filterTypes.number
      },
      {
        key: 'client_seed_id',
        type: filterTypes.number
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
